@font-face {
	font-family: 'Montserrat';
	font-weight: 400;
	font-style: normal;
	src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 600;
	font-style: normal;
	src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 700;
	font-style: normal;
	src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	src: url('./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf')
		format('truetype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 100;
	font-style: normal;
	src: url('./assets/fonts/SpoqaHanSansNeo-Thin.otf') format('opentype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 300;
	font-style: normal;
	src: url('./assets/fonts/SpoqaHanSansNeo-Light.otf') format('opentype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 400;
	font-style: normal;
	src: url('./assets/fonts/SpoqaHanSansNeo-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 500;
	font-style: normal;
	src: url('./assets/fonts/SpoqaHanSansNeo-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 700;
	font-style: normal;
	src: url('./assets/fonts/SpoqaHanSansNeo-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Tango Sans';
	font-weight: 400;
	font-style: normal;
	src: url('./assets/fonts/TangoSans.ttf') format('truetype');
}

@font-face {
	font-family: 'Tango Sans';
	font-weight: 700;
	font-style: normal;
	src: url('./assets/fonts/TangoSans_Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Tango Sans';
	font-weight: 400;
	font-style: italic;
	src: url('./assets/fonts/TangoSans_Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'Tango Sans';
	font-weight: 700;
	font-style: italic;
	src: url('./assets/fonts/TangoSans_BoldItalic.ttf') format('truetype');
}

:root {
	--color-main-black: #000000;
	--color-main-white: #ffffff;
	--color-main-light-gray: #b4b4b4;
	--color-sub-red: #e13521;
	--color-sub-blue: #005bff;
	--color-sub-yellow: #ffc700;
	--color-sub-gray: #7c7c7c;
	--color-sub-black: #0d0d0d;
	--color-sub-light-gray: #ececec;
}

* {
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	font-family: 'Spoqa Han Sans Neo';
	letter-spacing: -0.045rem;
	font-size: 10px;
}

html {
	background-color: black;
	width: 100vw;
	margin: 0 auto;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 1279px) {
		min-width: 100vw;
		width: 100%;
		max-height: 67.6rem;
		overflow: hidden;
	}
}

button {
	background: none;
	cursor: pointer;
	background-color: var(--color-sub-blue);
	color: var(--color-main-white);
}

a {
	color: inherit;
	text-decoration: none;
}
